import { combineReducers } from "redux";

export const sendEmail = (response = null, action) => {
    if (action.type === "SEND_EMAIL") return action.payload;
    return response;
};

export default combineReducers({
    sendEmail
});
