import React, { Component } from "react";
import { Parallax } from "react-parallax";

let centeredWrapperStyle = {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column"
};

let centeredBoxStyle = {
    background: "rgba(0,0,0,0.7)",
    color: "white",
    padding: "15px",
    borderRadius: "3px",
    boxShadow: "rgba(0, 0, 0, 0.4) 2px 2px 6px 2px"
};

let textStyle = {
    color: "white"
};

class Home extends Component {
    render() {
        return (
            <Parallax
                blur={0}
                bgImage={require("../../static/tom-adams-48380-unsplash.jpg")}
                bgImageAlt="the cat"
                strength={600}
            >
                <div style={{ height: "100vh", ...centeredWrapperStyle }}>
                    <div style={centeredBoxStyle}>
                        <div>
                            <h2 className="oldstandardtt home_title_complement_top" style={textStyle}>
                                Hi there! My name is
                            </h2>
                        </div>
                        <div>
                            <h2 className="bebas_neue home_title" style={textStyle}>
                                ALI HUSSEINAT
                            </h2>
                        </div>
                        <div>
                            <h2 className="oldstandardtt home_title_complement_bottom" style={textStyle}>
                                and this is my portifolio
                            </h2>
                        </div>
                    </div>
                </div>
            </Parallax>
        );
    }
}

export default Home;
