import React, { Component } from "react";
import { Divider } from "antd";

export default class Experience extends Component {
    render() {
        return (
            <div style={{ color: "black" }}>
                {this.props.hasDivider && <Divider />}
                <div style={{}}>
                    <p className="bebas_neue timeline_title" style={{ margin: "0" }}>
                        {this.props.title}
                    </p>
                    <p className="oldstandardtt timeline_subtitle" style={{ margin: "0" }}>
                        at {this.props.company}, from {this.props.fromDate}
                        {this.props.toDate !== "STILL_ON_IT" ? " to " + this.props.toDate : " until now"}
                    </p>
                </div>
                <br />
                <p className="oldstandardtt timeline_decription" style={{ padding: "0" }}>
                    {this.props.description}
                </p>
            </div>
        );
    }
}
