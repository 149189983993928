import React, { Component } from "react";
import { BrowserRouter, Route } from "react-router-dom";
import PortifolioPage from "./components/layout/PortifolioPage";

class App extends Component {
    render() {
        return (
            <BrowserRouter>
                <div>
                    <Route path="/" exact component={PortifolioPage} />
                </div>
            </BrowserRouter>
        );
    }
}

export default App;
