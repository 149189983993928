import React, { Component } from "react";

import Experience from "./Experience";

const profExperiences = [
    {
        title: "Cloud and Backend Engineer",
        company: "ARX",
        description:
            "I am working in a multidisciplinary team and my responsibility is to develop a platform to help companies in their tax sector. The software is being developed to be fully scalable, using cloud providers such as Microsoft Azure, so it can be marketed as a service.",
        fromDate: "Jan/2019",
        toDate: "today"
    },
    {
        title: "Software Engineer",
        company: "Monitora Soluções Tecnológicas",
        description:
            "I worked in a 9-member team to produce resilient distributed systems using Apache Kafka as the main technology between them, Java, Spring ecosystem and Redis (non-relational database). This job helped me to understand how the modern software is being made and how to produce fault-tolerant systems that can handle big workloads.",
        fromDate: "Apr/2018",
        toDate: "Jan/2019"
    },
    {
        title: "Software Engineer Intern",
        company: "Monitora Soluções Tecnológicas",
        description: "",
        fromDate: "Apr/2018",
        toDate: "Jan/2019"
    }
];

class ExperiencesList extends Component {
    render() {
        return (
            <div style={{ color: "black" }}>
                {profExperiences.map((e, id) => (
                    <Experience
                        description={e.description}
                        fromDate={e.fromDate}
                        toDate={e.toDate}
                        company={e.company}
                        title={e.title}
                        hasDivider={id !== 0}
                        key={id}
                    />
                ))}
            </div>
        );
    }
}

export default ExperiencesList;
