import React, { Component } from "react";
import { Col, Row } from "antd";
import { Element } from "react-scroll";

import ExperiencesTitle from "./ExperiencesTitle";
import ExperiencesList from "./ExperiencesList";

export default class ExperiencesSection extends Component {
    render() {
        return (
            <Element name="test1" className="element">
                <Row
                    style={{
                        padding: "10vh 5vw",
                        background: "linear-gradient(16deg, rgba(180,180,180,1) 0%, rgba(255,255,255,1) 30%)"
                    }}
                    id={"profExperiences"}
                >
                    <Col sm={24} md={8} className="scrollarea">
                        <ExperiencesTitle>My professional experiences</ExperiencesTitle>
                    </Col>
                    <Col sm={24} md={16} style={{ padding: "0 1vw" }}>
                        <ExperiencesList />
                    </Col>
                </Row>
            </Element>
        );
    }
}
