import React, { Component } from "react";
import { Menu } from "antd";

import { Link, Events, animateScroll as scroll } from "react-scroll";

const menubarTransparentStyle = {
    background: "rgba(0,0,0,0)"
};

const menubarDefaultStyle = {
    background: "rgba(0,0,0,1)",
    boxShadow: "0px 0px 10px -2px black"
};

class MenuBar extends Component {
    state = {
        current: "home",
        menuBarStyle: menubarTransparentStyle
    };

    handleClick = e => {
        this.setState({
            current: e.key
        });
    };

    listenScrollEvent = e => {
        if (window.scrollY !== 0) this.setState({ menuBarStyle: menubarDefaultStyle });
        else this.setState({ menuBarStyle: menubarTransparentStyle });
    };

    componentDidMount() {
        Events.scrollEvent.register("begin", function(to, element) {});
        Events.scrollEvent.register("end", function(to, element) {});
        window.addEventListener("scroll", this.listenScrollEvent);
    }
    componentWillUnmount() {
        Events.scrollEvent.remove("begin");
        Events.scrollEvent.remove("end");
    }
    render() {
        return (
            <Menu
                onClick={this.handleClick}
                selectedKeys={[this.state.current]}
                mode="horizontal"
                style={{
                    position: "fixed",
                    zIndex: "100",
                    width: "100%",
                    ...this.state.menuBarStyle
                }}
                className="main-menu hide_on_cp"
            >
                <Menu.Item key="logo" className="main-menu-item bebas_neue logo-item">
                    HUSSEINAT Portifolio
                </Menu.Item>
                <Menu.Item key="projects" className="main-menu-item main-menu-item-clickable bebas_neue">
                    <Link activeClass="active" to="test1" spy={true} smooth={true} duration={500}>
                        Test 1
                    </Link>
                </Menu.Item>
                <Menu.Item key="experiences" className="main-menu-item main-menu-item-clickable bebas_neue">
                    <Link activeClass="active" to="test1" spy={true} smooth={true} duration={500}>
                        Experiences
                    </Link>
                </Menu.Item>
                <Menu.Item key="certificates" className="main-menu-item main-menu-item-clickable bebas_neue">
                    Certificates
                </Menu.Item>
                <Menu.Item key="social" className="main-menu-item main-menu-item-clickable bebas_neue">
                    Social
                </Menu.Item>
                <Menu.Item key="contact" className="main-menu-item main-menu-item-clickable bebas_neue">
                    Contact
                </Menu.Item>
            </Menu>
        );
    }
}

export default MenuBar;
