import React, { Component } from "react";
import { Card } from "antd";

export default class Footer extends Component {
    render() {
        return (
            <Card
                style={{
                    width: "100%",
                    backgroundColor: "black",
                    borderRadius: 0,
                    color: "white",
                    fontSize: "19px"
                }}
                bodyStyle={{ padding: "2px" }}
                bordered={false}
                className="bebas_neue"
            >
                <p style={{ margin: 0, textAlign: "center" }}>
                    Built and designed by{" "}
                    <a
                        href={"https://github.com/Husseinat/"}
                        style={{ color: "grey" }}
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        Ali Husseinat
                    </a>
                </p>
            </Card>
        );
    }
}
