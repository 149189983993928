import React, { Component } from "react";
import Home from "./Home";
import Footer from "./Footer";
import ExperiencesSection from "../profExperiences/ExperiencesSection";
import ExperiencesGallery from "../profExperiences/ExperiencesGallery";
import MenuBar from "./MenuBar";
import "../../App.css";

class PortifolioPage extends Component {
    render() {
        return (
            <div className="App">
                <header className="App-header" />
                <MenuBar />
                <Home />
                <ExperiencesSection />
                <ExperiencesGallery />
                <Footer />
            </div>
        );
    }
}

export default PortifolioPage;
