import React, { Component } from "react";

import { Parallax } from "react-parallax";

export default class ParallaxDivider extends Component {
    render() {
        return (
            <div>
                <Parallax
                    blur={0}
                    bgImage={require("../../static/matthew-henry-15558-unsplash.jpg")}
                    bgImageAlt="the cat"
                    strength={600}
                >
                    <div style={{ height: this.props.height }} />
                </Parallax>
            </div>
        );
    }
}
